module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Emlyn Whittick","short_name":"Emlyn Whittick","start_url":"/","background_color":"#e6f7e4","theme_color":"#9ee096","display":"standalone","icon":"static/icons/emlyn.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1dc5ca5b7980d9e7e28152ff46442ac5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"webFontsConfig":{"fonts":{"google":[{"family":"Roboto Slab","variants":["300","400","500"]}]}}},
    },{
      plugin: require('../plugins/custom-mui-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
