import { useMediaQuery } from "@material-ui/core";
import React from "react";
import createTheme from "./theme";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@material-ui/core/styles";

export default function CustomTheme(props) {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(() => createTheme(darkMode), [darkMode]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  );
}