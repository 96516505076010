import { createMuiTheme } from "@material-ui/core/styles";

export default dark =>
  createMuiTheme(
    {
      palette: createPalette(dark),
      typography: {
        h1: {
          fontFamily: ["Roboto Slab", "Roboto", "sans-serif"].join(
            ","
          ),
          fontWeight: "400",
          fontSize: "3rem"
        },
        h2: {
          fontFamily: ["Roboto Slab", "Roboto", "sans-serif"].join(
            ","
          ),
          fontWeight: "400",
          fontSize: "2rem"
        },
        h3: {
          fontFamily: ["Roboto Slab", "Roboto", "sans-serif"].join(
            ","
          ),
          fontWeight: "400",
          fontSize: "1.5rem"
        },
        h4: {
          fontFamily: ["Roboto Slab", "Roboto", "sans-serif"].join(
            ","
          ),
          fontWeight: "400",
          fontSize: "1.2rem"
        },
        subtitle1: {
          fontFamily: ["Roboto Slab", "Roboto", "sans-serif"].join(
            ","
          )
        }
      }
    },
    "emlyn"
  );

const createPalette = dark => {
  return {
    type: dark ? "dark" : "light",
    primary: {
      main: "#00a81a"
    },
    secondary: {
      main: "#5a94b8"
    },
    ...(!dark && {
      text: {
        primary: "#2c2c2c",
        secondary: "#ffffff"
      },
      background: {
        default: "#ffffff",
        paper: "#ffffff"
      }
    })
  };
};